@import '~bootstrap/scss/bootstrap.scss';

body{
  background-color: #282c34;
}

#root{
  flex: 0 0 100%;
}

.Futha-main {
  height:auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  & p{
    text-align: justify ;
    margin:1em 0 2em 0}

  & a{text-decoration: none;}

}

.ceremony{
  margin-bottom: 3em!important;
  font-style: italic;
  padding-left:2em;
}

.particles-canvas {
  position:fixed !important;
  left:0;
  top:0;
  width:100%;
  height:100%;
}

.rune{
 font-size: 5em
}

.name{
  font-weight: bold;
  font-size: 2em
}

.content{
text-align: center;
position: relative;
top:20vh;
max-width: 60vw;
word-wrap: break-word;
}

.counter {
  min-height: 1.5em;
  &span{text-align: right!important;}
}

.about {
  top:15vh;
}

.form-control{
  width: 40vw;
  max-width: 40vw;
  margin: 0 auto;
}

.futhark{
  font-size: 2.5em;
  margin-bottom:2em;
  &:hover{cursor:pointer
  }
}

.navbar-brand{
  font-size:1.9em;
  margin-left:1em;
  line-height: 50px;
}

footer{
position: fixed;
height: 1.6em;
bottom: 0em;
width: 100%;
text-align: center;
color:white;
background: rgba(52, 58, 64, 1)!important;

}

.navbar{
  background: rgba(52, 58, 64, .5)!important;
}

span,code{
  color:red;
  margin: 0 .3em;
}
code,.navbar-brand{
  color:rgb(174, 174, 174)!important;
}

a{
  color:red;
  &:hover{
    color:red;
  }
  &:visited{
    color:red
  }
}